import React from 'react';
import { gsap } from 'gsap';
// import { ScrollTrigger } from "gsap/ScrollTrigger";

const delay = [
  [ "+=1", "+=0.01", "+=0.03" ],
  [ "+=4", "+=0.02", "+=0.04" ],
  [ "+=4", "+=0.01", "+=0.02" ],
];
const loopDelay = 1;

// const delay1 = "+=3";
// const delayMid1 = "+=0.01";
// const delayEnd1 = "+=0.2";

const blinkAnimation = () => {
  // gsap.registerPlugin(ScrollTrigger);
  const tl = gsap.timeline({
    repeat: -1,
    paused: false,
  });
  tl.add("start");

  delay.forEach( (a, i) => {
    const delayOpen = a[ 0 ];
    const delayMid  = a[ 1 ];
    const delayEnd  = a[ 2 ];

    tl.to( ".eyes1", {
      opacity: 1,
      ease: "none",
      duration: 0.001,
    }, delayOpen);
    tl.to( ".eyes2", {
      opacity: 1,
      ease: "none",
      duration: 0.001,
    }, delayMid);
    tl.to( ".eyes3", {
      opacity: 1,
      ease: "none",
      duration: 0.001,
    }, delayMid);
    tl.to( ".eyes4", {
      opacity: 1,
      ease: "none",
      duration: 0.001,
    }, delayMid);
    tl.to( ".eyes5", {
      opacity: 1,
      ease: "none",
      duration: 0.001,
    }, delayMid);
    tl.to( ".eyes6", {
      opacity: 1,
      ease: "none",
      duration: 0.001,
    }, delayMid);
    tl.to( ".eyes7", {
      opacity: 1,
      ease: "none",
      duration: 0.001,
    }, delayMid);
    tl.to( ".eyes7", {
      opacity: 0,
      ease: "none",
      duration: 0.001,
    }, delayEnd);
    tl.to( ".eyes6", {
      opacity: 0,
      ease: "none",
      duration: 0.001,
    }, delayMid);
    tl.to( ".eyes5", {
      opacity: 0,
      ease: "none",
      duration: 0.001,
    }, delayMid);
    tl.to( ".eyes4", {
      opacity: 0,
      ease: "none",
      duration: 0.001,
    }, delayMid);
    tl.to( ".eyes3", {
      opacity: 0,
      ease: "none",
      duration: 0.001,
    }, delayMid);
    tl.to( ".eyes2", {
      opacity: 0,
      ease: "none",
      duration: 0.001,
    }, delayMid);
    tl.to( ".eyes1", {
      opacity: 0,
      ease: "none",
      duration: 0.001,
    }, delayMid);
  });
  tl.repeatDelay( loopDelay );

}
export default blinkAnimation;
