// takes in a string, makes sure it looks like an email
const emailManipulate = (email) => {
  if ( typeof email !== "string" ) {
    return email;
  }
  // no spaces:
  email = email.replace(
    new RegExp("\\s", "g"), ""
  );
  // no emoji (this replaces most emoji with nothing):
  email = email.replace(
    new RegExp("([\\uE000-\\uF8FF]|\\uD83C[\\uDC00-\\uDFFF]|\\uD83D[\\uDC00-\\uDFFF]|[\\u2011-\\u26FF]|\\uD83E[\\uDD10-\\uDDFF])", "g"),
    ""
  );
  console.log(`"${email}"`);
  return email;
}
export default emailManipulate;
