import { gsap } from 'gsap';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import seeMoreAnim from './seeMoreAnim';

const jonTextAnimation = () => {
  gsap.registerPlugin(DrawSVGPlugin);
  const tl = gsap.timeline({
    paused: false,
  });
  tl.fromTo( ".jonText", {
    visibility: "visible",
    drawSVG: "0%",
  }, {
    drawSVG: "100%",
    duration: 0.5,
    ease: "none",
    stagger: 0.25,
  });
  tl.to( ".jonText", {
    fill: "#006356",
    duration: 1,
    ease: "none",
    onComplete: seeMoreAnim,
  });
}
export default jonTextAnimation;
