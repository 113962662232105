import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const seeMoreAnim = () => {
  gsap.registerPlugin(ScrollTrigger);
  const tl = gsap.timeline({
    repeat: -1,
    paused: false,
    scrollTrigger: {
      trigger: "#jon-spewak-logo",
      start: "top bottom",
      end: "bottom top",
      markers: false,
      toggleActions: "restart reset restart reset",
    }
  });
  tl.add("start", "+=0.1");
  tl.to(".chevron", {
    opacity: 1,
    duration: 2,
    stagger: 0.5,
  }, "start");
  tl.to(".chevron", {
    opacity: 0,
    duration: 1,
    stagger: 0.5,
  }, "start+=1.5");
}
export default seeMoreAnim;
