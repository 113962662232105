import React from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";

const doOne = true;
const doTwo = true;

const phoneAnimation = () => {
  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(DrawSVGPlugin);
  const tl = gsap.timeline({
    repeat: -1,
    paused: true,
    scrollTrigger: {
      trigger: "#phoneTrigger",
      start: "top bottom",
      end: "bottom top",
      markers: false,
      toggleActions: "restart reset restart reset",
    }
  });
  // tl.add("start");

  if ( doOne ) {
    tl.fromTo( ".svg001", {
      y: 0,
      opacity: 0,
    }, {
      y: 100,
      opacity: 1,
      ease: "power3.in",
      duration: 1,
    });
    tl.fromTo( ".svg002", {
      x: 0, opacity: 0,
    }, {
      x: 100,
      opacity: 1,
      ease: "power3.out",
      duration: 1,
    }, "+=0");
    // start blink
    tl.to( ".svg002", {
      opacity: 0,
      ease: "power3.out",
      duration: 0.5,
    }, "-=0.5");
    tl.to( ".svg002", {
      opacity: 1,
      ease: "power3.out",
      duration: 0.5,
    }, "+=0");
    tl.to( ".svg002", {
      opacity: 0,
      ease: "power3.out",
      duration: 0.5,
    });
    tl.fromTo( ".svg003", {
      opacity: 0,
    }, {
      opacity: 1,
      ease: "power3.out",
      duration: 0.5,
    }, "+=0");
    tl.fromTo( ".svg004", {
      opacity: 0,
      x: 0,
    }, {
      opacity: 1,
      x: 50,
      ease: "power3.out",
      duration: 0.3,
    }, ">-0.1");
    tl.fromTo( ".svg005", {
      opacity: 0,
      x: 0,
    }, {
      opacity: 1,
      x: 50,
      ease: "power3.out",
      duration: 0.3,
    }, ">-0.1");
    tl.fromTo( ".svg006", {
      opacity: 0,
      x: 0,
    }, {
      opacity: 1,
      x: 50,
      ease: "power3.out",
      duration: 0.3,
    }, ">-0.1");
    // button
    tl.fromTo( ".svg007", {
      opacity: 0,
      x: 0,
    }, {
      opacity: 1,
      x: 50,
      ease: "power3.out",
      duration: 0.3,
    }, ">-0.1");
    // pause, then clear
    tl.to( ".svgK", {
      opacity: 0,
      x: -100,
      duration: 1,
    }, "+=4");
    tl.to( ".svgK", {
      x: 0, y: 0,
      duration: 0.01,
    });
  }

  if ( doTwo ) {
    tl.fromTo( ".svg020", {
      opacity: 0,
      y: 0,
    }, {
      opacity: 1,
      y: 50,
      ease: "power3.in",
      duration: 1,
    }, "+=0");
    tl.fromTo( ".svg021", {
      opacity: 0,
      y: 0,
    }, {
      opacity: 1,
      y: 50,
      ease: "power3.out",
      duration: 1,
    }, "-=0.1");
    tl.fromTo( ".svg022", {
      opacity: 0,
      y: 0,
    }, {
      opacity: 1,
      y: 50,
      ease: "power3.out",
      duration: 1,
    }, "-=0.75");
    tl.fromTo( ".svg023", {
      opacity: 0, y: 0,
    }, {
      opacity: 1,
      y: 50,
      ease: "power3.out",
      duration: 1,
    }, "-=0.75");
    // hide username placeholder
    tl.to( ".svg022text", {
      opacity: 0,
      duration: 0.01,
    }, ">0");
    // write username text here:
    tl.staggerTo( ".svg024", 0.1, {
      opacity: 1,
      ease: "power4.out",
    }, 0.1, ">-0.8");
    tl.to( ".svg023text", {
      opacity: 0,
      duration: 0.01,
    }, ">0");
    tl.staggerTo( ".svg025", 0.1, {
      opacity: 1,
      ease: "power4.out",
    }, 0.1, ">-0.8");
    tl.to( ".uaSignIn", {
      opacity: 0,
      duration: 0.3,
    }, ">0.2");
    // hi jon
    tl.fromTo( ".svg026", {
      opacity: 0, x: 0,
    }, {
      opacity: 1,
      x: 50,
      ease: "power3.in",
      duration: 1,
    }, ">-0.5");
    tl.fromTo( ".svg027", {
      opacity: 0, x: 0,
    }, {
      opacity: 1,
      x: 50,
      ease: "power3.in",
      duration: 1,
    }, ">-0.5");
    tl.fromTo(".svg029", 1, {
      drawSVG: "0",
      visibility: "visible",
    }, {
      drawSVG: "0% 100%",
      ease: "none",
    }, ">0");
    tl.fromTo(".svg030", {
      opacity: 0, x: 0,
    }, {
      opacity: 1,
      x: 50,
      ease: "power3.in",
      duration: 1,
    }, ">-0.5");
    tl.fromTo( ".svg031", 1, {
      drawSVG: "0",
      visibility: "visible",
    }, {
      drawSVG: "0% 80%",
      ease: "power2.out",
    }, ">0.3");
    tl.to(".svg030", {
      opacity: 0,
      duration: 0.5,
      ease: "power3.out",
    }, ">0.3");
    tl.fromTo(".svg032", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 1,
      ease: "power3.in",
    }, ">-1.1");

    // pause, then clear
    tl.to( ".svgUA", {
      opacity: 0,
      duration: 1,
    }, "+=4");
    tl.to( ".svgUA", {
      x: 0, y: 0,
      duration: 0.01,
    });
  }
}
export default phoneAnimation;
