const trim = (str) => {
  str = str.replace(
    new RegExp("^\\s+"), ""
  );
  str = str.replace(
    new RegExp("\\s+$"), ""
  );
  return str;
}
export default trim;
