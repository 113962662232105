const randy = ({ min = 0, max, count = 1, order = "asc" }) => {
  // like, min:100 max:200, to get a number 100-200
  // count is number of randoms to return
  // order is asc or desc, when count > 1
  const r = max - min;
  if ( count === 1 ) {
    return Math.floor( Math.random() * r ) + min;
  }
  const ret = [];
  let i;
  for ( i = 1; i <= count; i ++ ) {
    ret.push( Math.floor( Math.random() * r ) + min );
  }
  if ( order === "desc" ) {
    return ret.sort( (a, b) => a - b );  // descending
  }
  return ret.sort( (a, b) => b - a );  // ascending
}
export default randy;
