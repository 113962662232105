// sets a cookie client-side
// note, http-only cookies cannot be set client-side

const DEBUG = process.env.DEV == 1;

const setCookie = ({name, value = "", path = "/", expDays}) => {
  // expirationDays is whole days, so 1 = 1 day and 0.166 = 4 hours
  if ( typeof name !== "string" || name.length === 0 ) {
    console.log("Cookie name cannot be blank");
    return ;
  }
  const re = new RegExp("^/");
  if ( ! path.match( re ) ) {
    console.log("Path must begin with a slash");
    return ;
  }
  let expires = "";
  if ( typeof expDays === "number" ) {
    const date = new Date();
    date.setTime( date.getTime() + (expDays * 24 * 60 * 60 * 1000) );
    expires = "; expires=" + date.toUTCString();
  }
  const cookie = `${name}=${value}${expires};path=${path};secure`;
  DEBUG && console.log( cookie );
  window.document.cookie = cookie;
  return true;
}
export default setCookie;
