// get cookies
const getCookie = (name) => {
  if ( typeof window === "undefined" || ! window.document ) {
    return null;
  }
  const cookieName = `${name}=`;
  const cookieArray = window.document.cookie.split(";");
  for ( let cookie of cookieArray ) {
    while (cookie.charAt(0) == " ") {
      cookie = cookie.substring( 1, cookie.length );
    }
    if (cookie.indexOf(cookieName) == 0) {
      return cookie.substring( cookieName.length, cookie.length );
    }
  }
}
export default getCookie;
